import React from "react";
import { Trans } from "@lingui/macro";
import { Typography } from "@mui/material";
import ArrowButton from "../components/ArrowButton";
import Container from "../components/Container";
import NewLayout from "../components/NewLayout";
import Seo from "../components/seo";

function NotFoundPage() {
  return (
    <NewLayout byDefault>
      <Seo />
      <Container p={8}>
        <Typography variant="h2">
          <Trans>404: Not Found</Trans>
        </Typography>
        <Typography>
          <Trans>
            You just hit a route that doesn't exist... the sadness.
          </Trans>
        </Typography>
        <ArrowButton mt={1} isDark title={<Trans>Back home</Trans>} path="/" />
      </Container>
    </NewLayout>
  );
}

export default NotFoundPage;
